import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatRow } from '@angular/material';
import { Observable } from 'rxjs';
import { SimpleTableDataSource } from './simple-table-datasource';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { merge } from "rxjs";
import { fromEvent } from 'rxjs';
import {Pivot} from '../../core/models/pivot'

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.css']
})
export class SimpleTableComponent implements AfterViewInit, OnInit {
  @Input() dataService;
  @Input() dropColumnList;
  @Input() columnList;
  @Input() actionList;
  @Input() dataFilter;
  @Input() serviceUrl: string = '';
  @Input() pivot: Pivot;
  @Input() loadToolbar = true;

  @Output() rowEvent = new EventEmitter<any>();
  
  dataSource: SimpleTableDataSource;

  private ENTITY_DATA = null;
  public count$: Observable<number>;
  public displayedColumns$: Observable<string[]>;
  private tableWidth;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  rowClickEvent(row: MatRow, source) {
    this.rowEvent.emit({row: row, source:source});
  }

  constructor() {
  }

  ngOnInit() {
    // paginator: document.getElementById('paginator');
    this.dataSource = new SimpleTableDataSource(this.dataService, 
      this.paginator, 
      this.sort, 
      this.columnList, 
      this.dropColumnList, 
      this.actionList,
      this.dataFilter,
      this.serviceUrl,
      this.pivot);
    this.dataSource.loadItems(null, null, 0, 20);
    this.displayedColumns$ = this.dataSource.getDisplayedColumns();

    //dynamic width
    this.displayedColumns$.subscribe(item=>{
      if (item.length > 10){
        this.tableWidth = 200 * item.length;
      }
    })
    
    this.count$ = this.dataSource.getCount();
  }

  refresh(){
    this.dataSource.loadItems(null, null, 0, 20);
  }
  
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    //server-side Pagination and Sorting
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.dataSource.loadPage())
      )
      .subscribe();


    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.dataSource.loadPage(this.input.nativeElement.value);
        })
      )
      .subscribe();
  }
}