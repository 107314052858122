/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Graph } from '../models/graph';
@Injectable({
  providedIn: 'root',
})
class GraphService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GraphService.GraphListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `node`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   *
   * - `graph_edges`:
   *
   * - `cluster_id`:
   *
   * - `algo_run`:
   */
  graphListResponse(params: GraphService.GraphListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Graph>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.node != null) __params = __params.set('node', params.node.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.graphEdges != null) __params = __params.set('graph_edges', params.graphEdges.toString());
    if (params.clusterId != null) __params = __params.set('cluster_id', params.clusterId.toString());
    if (params.algoRun != null) __params = __params.set('algo_run', params.algoRun.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/graph/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Graph>}>;
      })
    );
  }
  /**
   * @param params The `GraphService.GraphListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `node`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   *
   * - `graph_edges`:
   *
   * - `cluster_id`:
   *
   * - `algo_run`:
   */
  graphList(params: GraphService.GraphListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Graph>}> {
    return this.graphListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Graph>})
    );
  }

  /**
   * @param data undefined
   */
  graphCreateResponse(data: Graph): __Observable<__StrictHttpResponse<Graph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/graph/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Graph>;
      })
    );
  }
  /**
   * @param data undefined
   */
  graphCreate(data: Graph): __Observable<Graph> {
    return this.graphCreateResponse(data).pipe(
      __map(_r => _r.body as Graph)
    );
  }

  /**
   * @param id A unique integer value identifying this graph.
   */
  graphReadResponse(id: number): __Observable<__StrictHttpResponse<Graph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/graph/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Graph>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this graph.
   */
  graphRead(id: number): __Observable<Graph> {
    return this.graphReadResponse(id).pipe(
      __map(_r => _r.body as Graph)
    );
  }

  /**
   * @param params The `GraphService.GraphUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this graph.
   *
   * - `data`:
   */
  graphUpdateResponse(params: GraphService.GraphUpdateParams): __Observable<__StrictHttpResponse<Graph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/graph/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Graph>;
      })
    );
  }
  /**
   * @param params The `GraphService.GraphUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this graph.
   *
   * - `data`:
   */
  graphUpdate(params: GraphService.GraphUpdateParams): __Observable<Graph> {
    return this.graphUpdateResponse(params).pipe(
      __map(_r => _r.body as Graph)
    );
  }

  /**
   * @param params The `GraphService.GraphPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this graph.
   *
   * - `data`:
   */
  graphPartialUpdateResponse(params: GraphService.GraphPartialUpdateParams): __Observable<__StrictHttpResponse<Graph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/graph/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Graph>;
      })
    );
  }
  /**
   * @param params The `GraphService.GraphPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this graph.
   *
   * - `data`:
   */
  graphPartialUpdate(params: GraphService.GraphPartialUpdateParams): __Observable<Graph> {
    return this.graphPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Graph)
    );
  }

  /**
   * @param id A unique integer value identifying this graph.
   */
  graphDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/graph/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this graph.
   */
  graphDelete(id: number): __Observable<null> {
    return this.graphDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GraphService {

  /**
   * Parameters for graphList
   */
  export interface GraphListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    node?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    id?: number;
    graphEdges?: string;
    clusterId?: number;
    algoRun?: string;
  }

  /**
   * Parameters for graphUpdate
   */
  export interface GraphUpdateParams {

    /**
     * A unique integer value identifying this graph.
     */
    id: number;
    data: Graph;
  }

  /**
   * Parameters for graphPartialUpdate
   */
  export interface GraphPartialUpdateParams {

    /**
     * A unique integer value identifying this graph.
     */
    id: number;
    data: Graph;
  }
}

export { GraphService }
