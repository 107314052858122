/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { JSONWebToken } from '../models/jsonweb-token';
import { RefreshJSONWebToken } from '../models/refresh-jsonweb-token';
@Injectable({
  providedIn: 'root',
})
class TokenService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns a JSON Web Token that can be used for authenticated requests.
   * @param data undefined
   */
  tokenAuthCreateResponse(data: JSONWebToken): __Observable<__StrictHttpResponse<JSONWebToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/token/auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JSONWebToken>;
      })
    );
  }
  /**
   * Returns a JSON Web Token that can be used for authenticated requests.
   * @param data undefined
   */
  tokenAuthCreate(data: JSONWebToken): __Observable<JSONWebToken> {
    return this.tokenAuthCreateResponse(data).pipe(
      __map(_r => _r.body as JSONWebToken)
    );
  }

  /**
   * If 'orig_iat' field (original issued-at-time) is found, will first check
   * if it's within expiration window, then copy it to the new token
   * @param data undefined
   */
  tokenRefreshCreateResponse(data: RefreshJSONWebToken): __Observable<__StrictHttpResponse<RefreshJSONWebToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/token/refresh/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RefreshJSONWebToken>;
      })
    );
  }
  /**
   * If 'orig_iat' field (original issued-at-time) is found, will first check
   * if it's within expiration window, then copy it to the new token
   * @param data undefined
   */
  tokenRefreshCreate(data: RefreshJSONWebToken): __Observable<RefreshJSONWebToken> {
    return this.tokenRefreshCreateResponse(data).pipe(
      __map(_r => _r.body as RefreshJSONWebToken)
    );
  }
}

module TokenService {
}

export { TokenService }
