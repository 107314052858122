import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
  name: 'derp'
})
export class DerpPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Array.isArray(value) ? Array.from(value) : [value];
  }
}
  