import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iam',
  templateUrl: './iam.component.html',
  styleUrls: ['./iam.component.sass']
})
export class IamComponent implements OnInit {

  headerButtons = [
    {
      title: "ADD A USER",
      link: "#",
      icon: "add",
    },
    {
      title: "ADD A ROLE",
      link: "#",
      icon: "add",
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
