/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Aggregate } from '../models/aggregate';
@Injectable({
  providedIn: 'root',
})
class AggregateService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AggregateService.AggregateListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `metric`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   *
   * - `cluster_id`:
   */
  aggregateListResponse(params: AggregateService.AggregateListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Aggregate>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.metric != null) __params = __params.set('metric', params.metric.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.clusterId != null) __params = __params.set('cluster_id', params.clusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/aggregate/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Aggregate>}>;
      })
    );
  }
  /**
   * @param params The `AggregateService.AggregateListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `metric`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   *
   * - `cluster_id`:
   */
  aggregateList(params: AggregateService.AggregateListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Aggregate>}> {
    return this.aggregateListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Aggregate>})
    );
  }

  /**
   * @param data undefined
   */
  aggregateCreateResponse(data: Aggregate): __Observable<__StrictHttpResponse<Aggregate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/aggregate/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Aggregate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aggregateCreate(data: Aggregate): __Observable<Aggregate> {
    return this.aggregateCreateResponse(data).pipe(
      __map(_r => _r.body as Aggregate)
    );
  }

  /**
   * @param id A unique integer value identifying this aggregate.
   */
  aggregateReadResponse(id: number): __Observable<__StrictHttpResponse<Aggregate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/aggregate/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Aggregate>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this aggregate.
   */
  aggregateRead(id: number): __Observable<Aggregate> {
    return this.aggregateReadResponse(id).pipe(
      __map(_r => _r.body as Aggregate)
    );
  }

  /**
   * @param params The `AggregateService.AggregateUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this aggregate.
   *
   * - `data`:
   */
  aggregateUpdateResponse(params: AggregateService.AggregateUpdateParams): __Observable<__StrictHttpResponse<Aggregate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/aggregate/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Aggregate>;
      })
    );
  }
  /**
   * @param params The `AggregateService.AggregateUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this aggregate.
   *
   * - `data`:
   */
  aggregateUpdate(params: AggregateService.AggregateUpdateParams): __Observable<Aggregate> {
    return this.aggregateUpdateResponse(params).pipe(
      __map(_r => _r.body as Aggregate)
    );
  }

  /**
   * @param params The `AggregateService.AggregatePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this aggregate.
   *
   * - `data`:
   */
  aggregatePartialUpdateResponse(params: AggregateService.AggregatePartialUpdateParams): __Observable<__StrictHttpResponse<Aggregate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/aggregate/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Aggregate>;
      })
    );
  }
  /**
   * @param params The `AggregateService.AggregatePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this aggregate.
   *
   * - `data`:
   */
  aggregatePartialUpdate(params: AggregateService.AggregatePartialUpdateParams): __Observable<Aggregate> {
    return this.aggregatePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Aggregate)
    );
  }

  /**
   * @param id A unique integer value identifying this aggregate.
   */
  aggregateDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/aggregate/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this aggregate.
   */
  aggregateDelete(id: number): __Observable<null> {
    return this.aggregateDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AggregateService {

  /**
   * Parameters for aggregateList
   */
  export interface AggregateListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    metric?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    id?: number;
    clusterId?: string;
  }

  /**
   * Parameters for aggregateUpdate
   */
  export interface AggregateUpdateParams {

    /**
     * A unique integer value identifying this aggregate.
     */
    id: number;
    data: Aggregate;
  }

  /**
   * Parameters for aggregatePartialUpdate
   */
  export interface AggregatePartialUpdateParams {

    /**
     * A unique integer value identifying this aggregate.
     */
    id: number;
    data: Aggregate;
  }
}

export { AggregateService }
