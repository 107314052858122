import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormArray, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { ReplacePipe } from 'src/app/util/pipes/replace.pipe'
import { Dataset } from 'src/app/core/models';
import { DatasetService } from 'src/app/core/services';
import { MatSlideToggleModule, MatSlideToggleChange } from '@angular/material';
import { JsonPipe } from '@angular/common';
import { Job } from 'src/app/core/models/job';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-add-api',
  templateUrl: './add-api.component.html',
  styleUrls: ['./add-api.component.css']
})
export class AddApiComponent implements OnInit {
  schema: FormArray;
  private showProgress: boolean = false;
  private isError: boolean = false;
  private editAsText: boolean = false;
  loading$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() addAPISuccessEvent = new EventEmitter<any>();
  @ViewChild('input', { static: false }) input: ElementRef

  headerButtons = [
    {
      title: "Save draft",
      icon: 'save_alt'
    },
    {
      title: "Save and publish",
      icon: 'publish'
    },
    {
      title: "Cancel",
      icon: 'cancel'
    }
  ]

  //provides the reactive form group ability
  addApiGroup = this.fb.group({
    name: [null, Validators.required],
    description: [null],
    tags: [null],
    schema: this.fb.array([this.createSchema()]),
    schemaAsText: [null]
  });

  // provides the definition of Schema form group
  createSchema(name?: null, description?: null, type?: null, mode?: null): FormGroup {
    return this.fb.group({
      name: name,
      description: description,
      type: type,
      mode: mode,
    });
  }

  // provides the ability to create filter form controls on the fly
  addProperty(): void {
    this.schema = this.addApiGroup.get('schema') as FormArray;
    this.schema.push(this.createSchema());
  }

  removeProperty(): void {
    this.schema = this.addApiGroup.get('schema') as FormArray;
    this.schema.removeAt(this.schema.length - 1);
  }

  editAsTextToggleEvent(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.addApiGroup.controls['schemaAsText'].setValue(new JsonPipe().transform(this.addApiGroup.get('schema').value));
    }
    else {
      var schemaArray = JSON.parse(this.addApiGroup.get('schemaAsText').value)
      this.schema = this.addApiGroup.get('schema') as FormArray;
      this.schema.clear();
      schemaArray.forEach(item => {
        this.schema.push(this.createSchema(item.name, item.description, item.type, item.mode));
      });
    }
  }

  buttonClick(): void {
    if (event.currentTarget['id'] == "Cancel") {
      //reset form
      this.addApiGroup.reset();
      //workaround where validators states remains dirty even after submitting the form
      Object.keys(this.addApiGroup.controls).forEach(key => {
        this.addApiGroup.controls[key].setErrors(null)
      });
      this.addAPISuccessEvent.emit();
    }
    else if (event.currentTarget['id'] == "Save and publish") {
      this.loading$.next(true); //show progress bar

      var name = new ReplacePipe().transform(this.addApiGroup.get('name').value.toLowerCase(), ' ', '_');
      var description = this.addApiGroup.get('description').value;
      var tags = this.addApiGroup.get('tags').value;

      var config = JSON.stringify({
        name: new ReplacePipe().transform(this.addApiGroup.get('name').value.toLowerCase(), ' ', '_'),
        description: this.addApiGroup.get('description').value,
        properties: this.addApiGroup.get('schema').value,
      });

      //create, createdatasetapi job --> retrieve Id --> create dataset --> track progress, polling
      var job: Job = {
        name: name,
        description: 'createdatasetapi - ' + description,
        tags: tags,
        type: 'createdatasetapi',
        config: config
      }

      this.jobService.jobCreate(job).subscribe(
        result => {
          var dataset: Dataset = {
            name: name,
            description: description,
            tags: tags,
            config: config,
            status: 'deploying',
            job_id: result.id
          }

          this.datasetService.datasetCreate(dataset).subscribe(
            result => { },
            err => { this.isError = true; },
            () => {
              this.isError = false;
              this.loading$.next(false); //set loading to false

              //reset form
              this.addApiGroup.reset();
              //workaround where validators states remains dirty even after submitting the form
              Object.keys(this.addApiGroup.controls).forEach(key => {
                this.addApiGroup.controls[key].setErrors(null)
              });
              this.addAPISuccessEvent.emit();
            }
          )
        },
        err => { this.isError = true; },
        () => { }
      );

    }
    else if (event.currentTarget['id'] == "Save draft") {
      this.loading$.next(true); //show progress bar

      var name = new ReplacePipe().transform(this.addApiGroup.get('name').value.toLowerCase(), ' ', '_');
      var description = this.addApiGroup.get('description').value;
      var tags = this.addApiGroup.get('tags').value;

      var config = JSON.stringify({
        name: new ReplacePipe().transform(this.addApiGroup.get('name').value.toLowerCase(), ' ', '_'),
        description: this.addApiGroup.get('description').value,
        properties: this.addApiGroup.get('schema').value,
      });

      // create dataset in draft mode
      var dataset: Dataset = {
        name: name,
        description: description,
        tags: tags,
        config: config,
        status: 'draft'
      }

      this.datasetService.datasetCreate(dataset).subscribe(
        result => { },
        err => { this.isError = true; },
        () => {
          this.isError = false;
          this.loading$.next(false); //set loading to false

          //reset form
          this.addApiGroup.reset();
          //workaround where validators states remains dirty even after submitting the form
          Object.keys(this.addApiGroup.controls).forEach(key => {
            this.addApiGroup.controls[key].setErrors(null)
          });
          this.addAPISuccessEvent.emit();
        }
      )

    }
  }

  constructor(private fb: FormBuilder,
    private datasetService: DatasetService,
    private jobService: JobService) { }

  ngOnInit() {
  }

}
