import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { AlgoService, DatasetService } from 'src/app/core/services';
import { Subject, BehaviorSubject } from 'rxjs';
import { Algo } from 'src/app/core/models/algo';
import { MatSelectChange } from '@angular/material';
import { ReplacePipe } from 'src/app/util/pipes/replace.pipe';
import { CronOptions } from '../../core/models/cron-options';

@Component({
  selector: 'app-add-algo',
  templateUrl: './add-algo.component.html',
  styleUrls: ['./add-algo.component.css'],
  providers: [AlgoService]
})
export class AddAlgoComponent implements OnInit {
  public cronExpression = '0 12 1W 1/1 ?';
  public isCronDisabled = false;
  public cronOptions: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: '10:00:00',
    use24HourTime: true,

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: false,

    hideSeconds: true,
    removeSeconds: true,
    removeYears: true
  };

  filters: FormArray;
  edges: FormArray;
  private isError: boolean = false;

  loading$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() addAPISuccessEvent = new EventEmitter<any>();

  private selectedDataset;
  subTitle = ['ADD CONFIG']
  headerButtons = [
    {
      title: "Save draft",
      icon: 'save_alt'
    },
    {
      title: "Save draft and dry run",
      icon: 'play_circle_outline'
    },
    {
      title: "Save and publish",
      icon: 'publish'
    },
    {
      title: "Cancel",
      icon: 'cancel'
    }
  ]

  //provides the reactive form group ability
  addAlgoMetaGroup = this.fb.group({
    name: [null, Validators.required],
    description: [null],
    tags: [null]
  });

  //provides the reactive form group ability
  addAlgoGroup = this.fb.group({
    dataset: [null],
    node: [null, Validators.required],
    filters: this.fb.array([this.createFilter()]),
    edges: this.fb.array([this.createEdge()])
  });

  // provides the definition of Filter form group
  createFilter(): FormGroup {
    return this.fb.group({
      filter: [null],
      filterOperator: [null],
      filterValue: [null],
    });
  }

  // provides the definition of Edge form group
  createEdge(): FormGroup {
    return this.fb.group({
      edge: [null, Validators.required]
    });
  }

  // provides the ability to create filter form controls on the fly
  addFilter(): void {
    this.filters = this.addAlgoGroup.get('filters') as FormArray;
    this.filters.push(this.createFilter());
  }

  removeFilter(): void {
    this.filters = this.addAlgoGroup.get('filters') as FormArray;
    this.filters.removeAt(this.filters.length - 1);
  }

  // provides the ability to create edges form controls on the fly
  addEdge(): void {
    this.edges = this.addAlgoGroup.get('edges') as FormArray;
    this.edges.push(this.createEdge());
  }

  removeEdge(): void {
    this.edges = this.addAlgoGroup.get('edges') as FormArray;
    this.edges.removeAt(this.edges.length - 1);
  }

  datasetSelectChanged(event: MatSelectChange): void {
    var datasetId = event.value;
    this.datasetService.datasetRead(datasetId).subscribe(
      result => {
        this.selectedDataset = JSON.parse(result['config'])['properties'];
      },
      err => {
        this.isError = true;
      },
      () => {
        this.isError = false;
      }
    )
  }
  buttonClick(): void {
    debugger;
    if (event.currentTarget['id'] == "Cancel") {
      //reset form
      this.addAlgoGroup.reset();
      //workaround where validators states remains dirty even after submitting the form
      Object.keys(this.addAlgoGroup.controls).forEach(key => {
        this.addAlgoGroup.controls[key].setErrors(null)
      });
      this.addAPISuccessEvent.emit();
    }
    else if (event.currentTarget['id'] == "Save draft") {
      this.loading$.next(true); //show progress bar
      var name = new ReplacePipe().transform(this.addAlgoMetaGroup.get('name').value.toLowerCase(), ' ', '_')
      var description = this.addAlgoMetaGroup.get('description').value
      var tags = this.addAlgoMetaGroup.get('tags').value

      var config = {
        'name': name,
        'description': description,
        'properties': {
          'dataset': this.addAlgoGroup.get('dataset').value,
          'node': this.addAlgoGroup.get('node').value,
          'edges': this.addAlgoGroup.get('edges').value,
          'filters': this.addAlgoGroup.get('filters').value
        },
        'schedule': this.cronExpression
      }
      var algo: Algo = {
        name: name,
        description: description,
        tags: tags,
        config: JSON.stringify(config)
      }

      this.algoService.algoCreate(algo).subscribe(
        result => { },
        err => {
          this.isError = true;
        },
        () => {
          this.isError = false;
          //reset form
          this.addAlgoGroup.reset();
          //workaround where validators states remains dirty even after submitting the form
          Object.keys(this.addAlgoGroup.controls).forEach(key => {
            this.addAlgoGroup.controls[key].setErrors(null)
          });
          this.addAPISuccessEvent.emit();
        }
      )
    }
  }

  constructor(private fb: FormBuilder,
    private algoService: AlgoService,
    private datasetService: DatasetService) { }

  ngOnInit() {
  }

}
