import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { JSONWebToken } from 'src/app/core/models/jsonweb-token';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private isLoginFailed: boolean = false;

  //provides the reactive form group ability
  loginGroup = this.fb.group({
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  constructor(private fb: FormBuilder,
    private router: Router,
    private userService: AuthService) { }

  ngOnInit() {
  }

  login(): void {
    var user: JSONWebToken = {
      username: this.loginGroup.get('username').value,
      password: this.loginGroup.get('password').value
    }

    this.userService.login(user).subscribe(
      result => {
      },
      err => {
        this.isLoginFailed = true;
      },
      () => {
        this.router.navigateByUrl("home/dashboard");
      }
    )

    // if (this.loginGroup.get('username').value == 'admin' && this.loginGroup.get('password').value == 'admin') {
    //   this.router.navigateByUrl("user");
    // } else {
    //   alert("Invalid credentials");
    // }
  }

}
