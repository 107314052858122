import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatSelectChange } from '@angular/material';
import { Observable } from 'rxjs';
import { SimpleDropDownDataSource } from './simple-dropdown-datasource';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { merge } from "rxjs";
import { fromEvent } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core'

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.sass']
})
export class SimpleDropdownComponent implements OnInit {
  @Input() dataService;
  @Input() formCtrlName;
  @Input() placeHolder;
  @Input() formGroup;
  @Input() titleProperty: string = 'name';
  @Input() valueProperty: string = 'id';

  dataSource: SimpleDropDownDataSource;

  private ENTITY_DATA = null;
  // public count$: Observable<number>;
  // public displayedColumns$: Observable<string[]>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef

  @Output() onSelectChanged = new EventEmitter<any>();

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor() {
  }

  selectChangeEvent(event: MatSelectChange) {
    debugger;
    this.onSelectChanged.emit(event);
  }

  ngOnInit() {
    // paginator: document.getElementById('paginator');
    this.dataSource = new SimpleDropDownDataSource(this.dataService, this.paginator, this.sort);
    this.dataSource.loadItems(null, null, 0, null);
    // this.displayedColumns$ = this.dataSource.getDisplayedColumns();
    // this.count$ = this.dataSource.getCount();
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    //server-side Pagination and Sorting
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.dataSource.loadPage())
    //   )
    //   .subscribe();


    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          // this.paginator.pageIndex = 0;
          this.dataSource.loadPage(this.input.nativeElement.value, null, 0, null);
        })
      )
      .subscribe();
  }

}