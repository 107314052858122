import { Component, OnInit } from '@angular/core';
import { GroupService } from 'src/app/core/services';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
  providers: [GroupService]
})
export class GroupComponent implements OnInit {

  constructor(private dataService: GroupService) { }

  ngOnInit() {
  }

}
