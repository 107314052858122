import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent {

  chart1Roles = [
    { role: 'style', type: 'string', index: 2 }
  ];

  chart2Roles = {};

  chart3Roles = {};

  chart4Roles = {};

  chart1Data = [
    ['R#1', 12679, '#3E2723'],
    ['R#2', 21150, '#BA68C8'],
    ['R#3', 20985, '#9575CD'],
    ['R#4', 38215, '#7986CB'],
    ['R#5', 48012, '#64B5F6'],
    ['R#6', 21345, '#01579B'],
    ['R#7', 23478, '#4DD0E1'],
    ['R#8', 13789, '#4DB6AC'],
    ['R#9', 10289, '#4CAF50'],
    ['R#10', 29102, '#33691E'],
    ['R#11', 50874, '#C6FF00'],
    ['R#12', 11028, '#00BFA5'],
    ['R#13', 28994, '#F57F17'],
    ['R#14', 23988, '#FF9E80'],
    ['R#15', 99219, '#F06292'],
    ['R#16', 48012, '#64B5F6'],
    ['R#17', 21345, '#01579B'],
    ['R#18', 23478, '#4DD0E1'],
    ['R#19', 13789, '#4DB6AC'],
    ['R#20', 10289, '#4CAF50'],
    ['R#21', 29102, '#33691E'],
  ];

  chart2Data = [
    ['Germany', 202],
    ['United States', 211],
    ['Brazil', 200],
    ['Canada', 205],
    ['France', 210],
    ['RU', 206],
    ['India', 205]
  ]

  chart3Data = [
    ['Oct 1', 120, 123, 125],
    ['Oct 2', 122, 128, 126],
    ['Oct 3', 121, 113, 120],
    ['Oct 4', 120, 133, 123],
    ['Oct 5', 110, 128, 128],
    ['Oct 6', 116, 123, 129],
    ['Oct 7', 130, 122, 122],
    ['Oct 8', 128, 133, 124],
    ['Oct 9', 113, 125, 130],
    ['Oct 10', 145, 132, 127]
  ]

  chart4Data = [
    ['Oct 1', 120, 123, 125],
    ['Oct 2', 122, 128, 126],
    ['Oct 3', 121, 113, 120],
    ['Oct 4', 120, 133, 123],
    ['Oct 5', 110, 128, 128],
    ['Oct 6', 116, 123, 129],
    ['Oct 7', 130, 122, 122],
    ['Oct 8', 128, 133, 124],
    ['Oct 9', 113, 125, 130],
    ['Oct 10', 145, 132, 127]
  ]



  chart1Options = {
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    hAxis: {textStyle: {color: '#616161'}},
    vAxis: {textStyle: {color: '#616161'}}
  }

  chart2Options = {
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    hAxis: {textStyle: {color: '#616161'}},
    vAxis: {textStyle: {color: '#616161'}}
  }

  chart3Options = {
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    hAxis: {textStyle: {color: '#616161'}},
    vAxis: {textStyle: {color: '#616161'}}
  }

  chart4Options = {
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    hAxis: {textStyle: {color: '#616161'}},
    vAxis: {textStyle: {color: '#616161'}}
  }

  chart1 = {
    title: "",
    type: "ColumnChart",
    data: this.chart1Data,
    columnNames: ["country", "hits"],
    roles: this.chart1Roles,
    options: this.chart1Options
  }

  chart2 = {
    title: "",
    type: "GeoChart",
    data: this.chart2Data,
    columnNames: ["country", "hits"],
    roles: null,
    options: this.chart2Options
  }

  chart3 = {
    title: "",
    type: "LineChart",
    data: this.chart3Data,
    columnNames: ["Date", "API#1", "API#2", "API#3"],
    roles: null,
    options: this.chart3Options
  }

  chart4 = {
    title: "",
    type: "AreaChart",
    data: this.chart4Data,
    columnNames: ["Date", "C#1", "C#2", "C3#3"],
    roles: null,
    options: this.chart4Options
  }

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'RULES', cols: 1, rows: 1, chart: this.chart1 },
          { title: 'API', cols: 1, rows: 1, chart: this.chart3 },
          { title: 'DEMOGRAPHICS', cols: 1, rows: 1, chart: this.chart2 },
          { title: 'USAGE', cols: 1, rows: 1, chart: this.chart4 }
        ];
      }

      return [
        { title: 'RULES', cols: 2, rows: 1, chart: this.chart1 },
        { title: 'API', cols: 1, rows: 1, chart: this.chart3 },
        { title: 'DEMOGRAPHICS', cols: 1, rows: 2, chart: this.chart2 },
        { title: 'USAGE', cols: 1, rows: 1, chart: this.chart4 }
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) { }
}
