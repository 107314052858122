import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, finalize } from "rxjs/operators";
import { of } from "rxjs";
import { GraphService } from '../../core/services/graph.service'
import { EdgeService } from '../../core/services/edge.service'



/**
 * Data source for the graph view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including clusters and edges).
 */

export class GraphDataSource extends DataSource<any> {

    // private count: number;
    // private next?: string;
    // private previous?: string;
    // private displayedColumns: string[];

    public result = new BehaviorSubject<any>([]);
    private subject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    // public countSubject = new BehaviorSubject<string>('');
    // public nextSubject = new BehaviorSubject<string>('');
    // public previousSubject = new BehaviorSubject<string>('');
    // public displayedColumnsSubject = new BehaviorSubject<string[]>([]);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private graphService: GraphService,
        private edgeService: EdgeService) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<any> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.loadingSubject.complete();
    }

    loadItems(limit: number = 10000, clusterId) {

        this.loadingSubject.next(true);
        // fetch graph
        this.graphService.graphList({ limit, clusterId }).subscribe(
            graphResponse => {
                //call edge service
                var graph = clusterId;
                this.edgeService.edgeList({ limit, graph }).subscribe(
                    edgeResponse => {
                        this.result.next({
                            "nodes": graphResponse['results'],
                            "links": edgeResponse['results']
                        });
                        //if we are here life is beautiful, 
                        //lets merge graph and edges
                    },
                    error => { },
                    () => { }
                )
            },
            error => { },
            () => {

            }
        )

        //fetch edges

        // this.dataService[methodName]({ limit }).pipe(
        //     catchError(() => of([])),
        //     finalize(() => this.loadingSubject.next(false))
        // )
        //     // .subscribe(contacts => this.contactsSubject.next(contacts));
        //     .subscribe(response => {
        //         this.subject.next(response['results']);
        //         // this.countSubject.next(response['count']);
        //         // this.nextSubject.next(response['next']);
        //         // this.previousSubject.next(response['previous']);
        //         // this.displayedColumnsSubject.next(response['results'] ? Object.keys(response['results'][0]) : []);
        //     }
        //     );
    }

}