/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Permission } from '../models/permission';
@Injectable({
  providedIn: 'root',
})
class PermissionService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PermissionService.PermissionListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  permissionListResponse(params: PermissionService.PermissionListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Permission>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/permission/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Permission>}>;
      })
    );
  }
  /**
   * @param params The `PermissionService.PermissionListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  permissionList(params: PermissionService.PermissionListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Permission>}> {
    return this.permissionListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Permission>})
    );
  }

  /**
   * @param data undefined
   */
  permissionCreateResponse(data: Permission): __Observable<__StrictHttpResponse<Permission>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/permission/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Permission>;
      })
    );
  }
  /**
   * @param data undefined
   */
  permissionCreate(data: Permission): __Observable<Permission> {
    return this.permissionCreateResponse(data).pipe(
      __map(_r => _r.body as Permission)
    );
  }

  /**
   * @param id A unique integer value identifying this permission.
   */
  permissionReadResponse(id: number): __Observable<__StrictHttpResponse<Permission>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/permission/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Permission>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this permission.
   */
  permissionRead(id: number): __Observable<Permission> {
    return this.permissionReadResponse(id).pipe(
      __map(_r => _r.body as Permission)
    );
  }

  /**
   * @param params The `PermissionService.PermissionUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this permission.
   *
   * - `data`:
   */
  permissionUpdateResponse(params: PermissionService.PermissionUpdateParams): __Observable<__StrictHttpResponse<Permission>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/permission/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Permission>;
      })
    );
  }
  /**
   * @param params The `PermissionService.PermissionUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this permission.
   *
   * - `data`:
   */
  permissionUpdate(params: PermissionService.PermissionUpdateParams): __Observable<Permission> {
    return this.permissionUpdateResponse(params).pipe(
      __map(_r => _r.body as Permission)
    );
  }

  /**
   * @param params The `PermissionService.PermissionPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this permission.
   *
   * - `data`:
   */
  permissionPartialUpdateResponse(params: PermissionService.PermissionPartialUpdateParams): __Observable<__StrictHttpResponse<Permission>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/permission/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Permission>;
      })
    );
  }
  /**
   * @param params The `PermissionService.PermissionPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this permission.
   *
   * - `data`:
   */
  permissionPartialUpdate(params: PermissionService.PermissionPartialUpdateParams): __Observable<Permission> {
    return this.permissionPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Permission)
    );
  }

  /**
   * @param id A unique integer value identifying this permission.
   */
  permissionDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/permission/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this permission.
   */
  permissionDelete(id: number): __Observable<null> {
    return this.permissionDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PermissionService {

  /**
   * Parameters for permissionList
   */
  export interface PermissionListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for permissionUpdate
   */
  export interface PermissionUpdateParams {

    /**
     * A unique integer value identifying this permission.
     */
    id: number;
    data: Permission;
  }

  /**
   * Parameters for permissionPartialUpdate
   */
  export interface PermissionPartialUpdateParams {

    /**
     * A unique integer value identifying this permission.
     */
    id: number;
    data: Permission;
  }
}

export { PermissionService }
