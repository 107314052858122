import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpXhrBackend, XhrFactory } from '@angular/common/http';
import { JwtInterceptor } from 'src/app/core/interceptors/jwt.interceptor';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger',
  templateUrl: './swagger.component.html',
  styleUrls: ['./swagger.component.sass']
})
export class SwaggerComponent implements OnInit {
  @Output() detailCloseEvent = new EventEmitter<any>();
  @Input() clickedRow;

  headerButtons = [
    {
      title: "Close"
    }
  ]

  buttonClick(): void {
    if (event.currentTarget['id'] == "Close") {
      this.detailCloseEvent.emit();
    }
  }

  constructor(private http: HttpClient, private httpInterceptor: JwtInterceptor) {

  }

  ngOnInit() {
    debugger;
    var hInterceptor = this.httpInterceptor;

    const ui = SwaggerUIBundle({
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url: this.clickedRow['api_url'] + 'swagger/',
      docExpansion: 'none',
      operationsSorter: 'alpha',
      securityDefinitions: {
        JWT: {
          type: "apiKey",
          description: "",
          name: "Authorization",
          in: "header"
        }
      },
      // requestInterceptor: function(request){
      //   console.log('[Swagger] intercept try-it-out request');
      //   let currentUser = hInterceptor.userService.currentUserValue;
      //   var token = `Bearer ${currentUser.token}`
      //   request.headers.Authorization = token;
      //   return request;
      // }
      // requestInterceptor: function(request){
      //   var rq = new HttpRequest(
      //     "GET", 
      //     request.url,
      //     request.body, {
      //     headers: new HttpHeaders(request.headers),
      //     responseType: request.responseType,
      //   })
      //   hInterceptor.intercept(rq, new HttpXhrBackend({ build: () => new XMLHttpRequest }));
      // } 
      //  
        
      
                // function (request) {
                //     console.log('[Swagger] intercept try-it-out request');
                //     request.headers.Authorization = authenticationToken;
                //     return request;
                // }
    });

    // ui.authActions.authorize({
    //   JWT: {
    //     name: "JWT",
    //     schema: { type: "apiKey", in: "header", name: "Authorization", description: "" },
    //     value: "MY_TOKEN"
    //   }
    // });
  }

}
