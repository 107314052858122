/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Dataset } from '../models/dataset';
@Injectable({
  providedIn: 'root',
})
class DatasetService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DatasetService.DatasetListParams` containing the following parameters:
   *
   * - `updated_by`:
   *
   * - `status`:
   *
   * - `search`: A search term.
   *
   * - `owner`:
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `job_id`:
   *
   * - `is_internal`:
   *
   * - `id`:
   *
   * - `hits`:
   *
   * - `description`:
   *
   * - `dataset_type`:
   *
   * - `api_url`:
   */
  datasetListResponse(params: DatasetService.DatasetListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Dataset>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.updatedBy != null) __params = __params.set('updated_by', params.updatedBy.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.owner != null) __params = __params.set('owner', params.owner.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.jobId != null) __params = __params.set('job_id', params.jobId.toString());
    if (params.isInternal != null) __params = __params.set('is_internal', params.isInternal.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.hits != null) __params = __params.set('hits', params.hits.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.datasetType != null) __params = __params.set('dataset_type', params.datasetType.toString());
    if (params.apiUrl != null) __params = __params.set('api_url', params.apiUrl.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dataset/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Dataset>}>;
      })
    );
  }
  /**
   * @param params The `DatasetService.DatasetListParams` containing the following parameters:
   *
   * - `updated_by`:
   *
   * - `status`:
   *
   * - `search`: A search term.
   *
   * - `owner`:
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `job_id`:
   *
   * - `is_internal`:
   *
   * - `id`:
   *
   * - `hits`:
   *
   * - `description`:
   *
   * - `dataset_type`:
   *
   * - `api_url`:
   */
  datasetList(params: DatasetService.DatasetListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Dataset>}> {
    return this.datasetListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Dataset>})
    );
  }

  /**
   * @param data undefined
   */
  datasetCreateResponse(data: Dataset): __Observable<__StrictHttpResponse<Dataset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dataset/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dataset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  datasetCreate(data: Dataset): __Observable<Dataset> {
    return this.datasetCreateResponse(data).pipe(
      __map(_r => _r.body as Dataset)
    );
  }

  /**
   * @param id A unique integer value identifying this dataset.
   */
  datasetReadResponse(id: number): __Observable<__StrictHttpResponse<Dataset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dataset/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dataset>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this dataset.
   */
  datasetRead(id: number): __Observable<Dataset> {
    return this.datasetReadResponse(id).pipe(
      __map(_r => _r.body as Dataset)
    );
  }

  /**
   * @param params The `DatasetService.DatasetUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this dataset.
   *
   * - `data`:
   */
  datasetUpdateResponse(params: DatasetService.DatasetUpdateParams): __Observable<__StrictHttpResponse<Dataset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dataset/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dataset>;
      })
    );
  }
  /**
   * @param params The `DatasetService.DatasetUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this dataset.
   *
   * - `data`:
   */
  datasetUpdate(params: DatasetService.DatasetUpdateParams): __Observable<Dataset> {
    return this.datasetUpdateResponse(params).pipe(
      __map(_r => _r.body as Dataset)
    );
  }

  /**
   * @param params The `DatasetService.DatasetPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this dataset.
   *
   * - `data`:
   */
  datasetPartialUpdateResponse(params: DatasetService.DatasetPartialUpdateParams): __Observable<__StrictHttpResponse<Dataset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/dataset/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dataset>;
      })
    );
  }
  /**
   * @param params The `DatasetService.DatasetPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this dataset.
   *
   * - `data`:
   */
  datasetPartialUpdate(params: DatasetService.DatasetPartialUpdateParams): __Observable<Dataset> {
    return this.datasetPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Dataset)
    );
  }

  /**
   * @param id A unique integer value identifying this dataset.
   */
  datasetDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dataset/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this dataset.
   */
  datasetDelete(id: number): __Observable<null> {
    return this.datasetDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DatasetService {

  /**
   * Parameters for datasetList
   */
  export interface DatasetListParams {
    updatedBy?: string;
    status?: string;

    /**
     * A search term.
     */
    search?: string;
    owner?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    name?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    jobId?: string;
    isInternal?: string;
    id?: number;
    hits?: number;
    description?: string;
    datasetType?: string;
    apiUrl?: string;
  }

  /**
   * Parameters for datasetUpdate
   */
  export interface DatasetUpdateParams {

    /**
     * A unique integer value identifying this dataset.
     */
    id: number;
    data: Dataset;
  }

  /**
   * Parameters for datasetPartialUpdate
   */
  export interface DatasetPartialUpdateParams {

    /**
     * A unique integer value identifying this dataset.
     */
    id: number;
    data: Dataset;
  }
}

export { DatasetService }
