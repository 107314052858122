export * from './node-visual/node-visual.component';
export * from './link-visual/link-visual.component';
export * from './graph-visual/graph-visual.component';

import { NodeVisualComponent } from './node-visual/node-visual.component';
import { LinkVisualComponent } from './link-visual/link-visual.component';
import { GraphVisualComponent } from './graph-visual/graph-visual.component';

export const SHARED_COMPONENTS = [
    NodeVisualComponent,
    LinkVisualComponent,
    GraphVisualComponent
];