import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AddPolicyComponent } from './components/add-policy/add-policy.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ActivityComponent } from './components/activity/activity.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { PolicyComponent } from './components/policy/policy.component';
import { DatasetComponent } from './components/dataset/dataset.component';
import { IamComponent } from './components/iam/iam.component';
import { BillingComponent } from './components/billing/billing.component';
import { AlgoComponent } from './components/algo/algo.component';
import { ReportComponent } from './components/report/report.component';
import { AddAlgoComponent } from './components/add-algo/add-algo.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AddApiComponent } from './components/add-api/add-api.component';
import { DatasetDetailsComponent } from './components/dataset-details/dataset-details.component';
import { JobComponent } from './components/job/job.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [{
      path: 'dashboard',
      component: DashboardComponent,
      data: { breadcrumb: 'Dashboard' }
    },
    {
      path: 'activity',
      component: ActivityComponent,
      data: { breadcrumb: 'activity' }
    },
    {
      path: 'policy',
      component: PolicyComponent,
      data: { breadcrumb: 'Policy' },
      children: [{
        path: 'add-policy',
        component: AddPolicyComponent,
        data: { breadcrumb: 'Add Policy' }
      }]
    },
    {
      path: 'data',
      component: DatasetComponent,
      data: { breadcrumb: 'Data' },
      children: [{
        path: ':id',
        component: DatasetDetailsComponent,
        data: { breadcrumb: 'Dataset Detail' }
      }]
    },
    {
      path: 'iam',
      component: IamComponent,
      data: { breadcrumb: 'IAM' }
    },
    {
      path: 'job',
      component: JobComponent,
      data: { breadcrumb: 'JOB' }
    },
    {
      path: 'billing',
      component: BillingComponent,
      data: { breadcrumb: 'BILLING' }
    },
    {
      path: 'algo',
      component: AlgoComponent,
      data: { breadcrumb: 'ALGO' },
      children: [{
        path: 'add-algo',
        component: AddAlgoComponent,
        data: { breadcrumb: 'ADD NEW ALGO' }
      }]
    },
    {
      path: 'report',
      component: ReportComponent,
      data: { breadcrumb: 'REPORT' }
    }]
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
