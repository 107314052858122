/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

// import { Tag } from '../models/tag';
@Injectable({
  providedIn: 'root',
})
class ResourceService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ResourceService.ResourceListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  resourceListResponse(params: ResourceService.ResourceListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<any>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
    //   this.rootUrl + `/tag/`,
        params.url,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<any>}>;
      })
    );
  }
  /**
   * @param params The `ResourceService.ResourceListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  resourceList(params: ResourceService.ResourceListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<any>}> {
    return this.resourceListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<any>})
    );
  }

  /**
   * @param data undefined
   */
  resourceCreateResponse(data: any, url: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
    //   this.rootUrl + `/tag/`,
        url,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * @param data undefined
   */
  resourceCreate(data: any, url: string): __Observable<any> {
    return this.resourceCreateResponse(data, url).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * @param id A unique integer value identifying this tag.
   */
  resourceReadResponse(id: number, url: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
    //   this.rootUrl + `/tag/${id}/`,
        url + `${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this tag.
   */
  resourceRead(id: number, url: string): __Observable<any> {
    return this.resourceReadResponse(id, url).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * @param params The `ResourceService.ResourceUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  resourceUpdateResponse(params: ResourceService.ResourceUpdateParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
    //   this.rootUrl + `/tag/${params.id}/`,
        params.url + `${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * @param params The `ResourceService.ResourceUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  resourceUpdate(params: ResourceService.ResourceUpdateParams): __Observable<any> {
    return this.resourceUpdateResponse(params).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * @param params The `ResourceService.ResourcePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  resourcePartialUpdateResponse(params: ResourceService.ResourcePartialUpdateParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
    //   this.rootUrl + `/tag/${params.id}/`,
        params.url + `${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * @param params The `ResourceService.ResourcePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  resourcePartialUpdate(params: ResourceService.ResourcePartialUpdateParams): __Observable<any> {
    return this.resourcePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as any)
    );
  }

  /**
   * @param id A unique integer value identifying this tag.
   */
  resourceDeleteResponse(id: number, url: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
        url + `${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this tag.
   */
  resourceDelete(id: number, url: string): __Observable<null> {
    return this.resourceDeleteResponse(id, url).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ResourceService {

  /**
   * Parameters for tagList
   */
  export interface ResourceListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * api url
     */
    url: string;
  }

  /**
   * Parameters for tagUpdate
   */
  export interface ResourceUpdateParams {

    /**
     * A unique integer value identifying this tag.
     */
    id: number;
    data: any;

    /**
     * api url
     */
    url: string;
  }

  /**
   * Parameters for tagPartialUpdate
   */
  export interface ResourcePartialUpdateParams {

    /**
     * A unique integer value identifying this tag.
     */
    id: number;
    data: any;

    /**
     * api url
     */
    url: string;
  }
}

export { ResourceService }
