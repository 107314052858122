import { Component, OnInit, ViewChild } from '@angular/core';
import { DatasetService } from 'src/app/core/services';
import { MatSidenav, MatRow } from '@angular/material';
import { SimpleTableComponent } from '../simple-table/simple-table.component';

@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.css'],
  providers: [DatasetService]
})
export class DatasetComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer: MatSidenav;
  @ViewChild('tableInternal', { static: false }) tableInternal: SimpleTableComponent;

  private dropColumnList = ['id', 'schema'];
  private internalColumnList = ['name', 'description', 'hits', 'create_date', 'update_date', 'owner', 'tags', 'dataset_type', 'status', 'action']
  private externalColumnList = ['name', 'description', 'create_date', 'update_date', 'owner', 'tags', 'dataset_type', 'status', 'action']

  private loadAddApiComponent = false; //flag to show or hide add api cmponent
  private loadDatasetDetailComponent = true; //flag to show or hide dataset details cmponent
  private loadApiDetailComponent = false; //flag to show or hide dataset details cmponent

  private clickedRow: MatRow = {};

  headerButtons = [
    {
      title: "ADD DATA API",
      link: "#",
      icon: "add",
    }
  ]

  private internalDatasetActions = [
    { title: 'View', icon: 'view_column' },
    { title: 'API', icon: 'share' },
    { title: 'Edit', icon: 'edit' },
    { title: 'Delete', icon: 'delete' },
    { title: 'Publish', icon: 'publish' },
    { title: 'Data upload utility', icon: 'cloud_download' }
  ]

  private externalDatasetActions = [
    { title: 'View', icon: 'view_column' },
    { title: 'Delete', icon: 'delete' }
  ]

  private internalDataFilter: DatasetService.DatasetListParams = {isInternal: 'true'}

  private externalDataFilter: DatasetService.DatasetListParams = {isInternal: 'false'}

  addAPIClickEvent(): void {
    this.loadAddApiComponent = true;
    this.loadDatasetDetailComponent = false;
    this.loadApiDetailComponent = false;
    this.drawer.toggle();
  };

  tableRowClickEvent(item): void {
    var row = item.row;
    var source = item.source;

    if (source == null || source.title.toLowerCase() == "view") {
      this.loadAddApiComponent = false;
      this.loadDatasetDetailComponent = true;
      this.loadApiDetailComponent = false;
      this.clickedRow = row;
      this.drawer.toggle();
    }
    else if (source.title.toLowerCase() == "api") {
      this.loadAddApiComponent = false;
      this.loadDatasetDetailComponent = false;
      this.loadApiDetailComponent = true;
      this.clickedRow = row;
      this.drawer.toggle();
    }
    else {
      alert(source.title + ' clicked!')
    }

  };

  addAPISuccessEvent(): void {
    this.drawer.toggle();
    this.tableInternal.refresh();
  };

  datasetDetailCloseEvent(): void {
    this.drawer.toggle();
    this.loadAddApiComponent = false;
    this.loadDatasetDetailComponent = false;
    this.loadApiDetailComponent = false;
  }

  apiDetailCloseEvent(): void {
    this.drawer.toggle();
    this.loadAddApiComponent = false;
    this.loadDatasetDetailComponent = false;
    this.loadApiDetailComponent = false;
  }

  constructor(private dataService: DatasetService) { }

  ngOnInit() {
  }

}
