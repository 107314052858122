import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { GraphService } from 'src/app/core/services/graph.service';
import { EdgeService } from 'src/app/core/services/edge.service';
import { GraphDataSource } from './graph.datasource';
import { Node, Link } from 'src/app/shared/models';
import { GraphVisualComponent } from 'src/app/shared/components';
import APP_CONFIG from '../../app.config'

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
  providers: [GraphService, EdgeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphComponent implements OnInit {
  @Input() clusterId: number;
  @ViewChild('graph', { static: false }) graphVisualComponent: GraphVisualComponent;

  nodes: Node[] = [];
  links: Link[] = [];
  dataSource: GraphDataSource;
  private isLoadGraph = false;


  constructor(private graphService: GraphService,
    private edgeService: EdgeService) { }

  ngOnInit() {
    // const N = APP_CONFIG.N,
    //   getIndex = number => number - 1;

    // /** constructing the nodes array */
    // for (let i = 1; i <= N; i++) {
    //   this.nodes.push(new Node(i));
    // }

    // for (let i = 1; i <= N; i++) {
    //   for (let m = 2; i * m <= N; m++) {
    //     /** increasing connections toll on connecting nodes */
    //     this.nodes[getIndex(i)].linkCount++;
    //     this.nodes[getIndex(i * m)].linkCount++;

    //     /** connecting the nodes before starting the simulation */
    //     this.links.push(new Link(i, i * m));
    //   }
    // }

    this.dataSource = new GraphDataSource(this.graphService, this.edgeService);
    // this.dataSource.loadItems(null, this.clusterId);
    this.dataSource.result.subscribe(
      data => {
        if (data.nodes) {
          debugger;
          // this.nodes = data.nodes;
          // this.links = data.links;
          this.isLoadGraph = true;
          // this.graphVisualComponent.nodes = data.nodes;
          this.graphVisualComponent.nodes = [];
          this.graphVisualComponent.links = [];
          data.nodes.forEach(
            item => this.graphVisualComponent.nodes.push(new Node(item['node'])));

          data.links.forEach(
            item => this.graphVisualComponent.links.push(new Link(item['source'], item['target'])));
          // this.graphVisualComponent.links = data.links;
          this.graphVisualComponent.ngOnInit();
        };

      }
    )

    this.loadGraph(this.clusterId);

  }

  loadGraph(clusterId): void {
    this.isLoadGraph = false;
    this.clusterId = clusterId;
    this.dataSource.loadItems(null, this.clusterId);
    // this.isLoadGraph = true;
  }

}
