/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  parametersAggregateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/aggregate/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersAggregate(): __Observable<null> {
    return this.parametersAggregateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this aggregate.
   */
  parametersAggregateIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/aggregate/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this aggregate.
   */
  parametersAggregateId(id: number): __Observable<null> {
    return this.parametersAggregateIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersAlgoResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/algo/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersAlgo(): __Observable<null> {
    return this.parametersAlgoResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this algo.
   */
  parametersAlgoIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/algo/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this algo.
   */
  parametersAlgoId(id: number): __Observable<null> {
    return this.parametersAlgoIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersAlgorunResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/algorun/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersAlgorun(): __Observable<null> {
    return this.parametersAlgorunResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this algo run.
   */
  parametersAlgorunIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/algorun/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this algo run.
   */
  parametersAlgorunId(id: number): __Observable<null> {
    return this.parametersAlgorunIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersDatasetResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/dataset/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersDataset(): __Observable<null> {
    return this.parametersDatasetResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this dataset.
   */
  parametersDatasetIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/dataset/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this dataset.
   */
  parametersDatasetId(id: number): __Observable<null> {
    return this.parametersDatasetIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersDatasetschemaResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/datasetschema/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersDatasetschema(): __Observable<null> {
    return this.parametersDatasetschemaResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this dataset schema.
   */
  parametersDatasetschemaIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/datasetschema/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this dataset schema.
   */
  parametersDatasetschemaId(id: number): __Observable<null> {
    return this.parametersDatasetschemaIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersEdgeResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/edge/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersEdge(): __Observable<null> {
    return this.parametersEdgeResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this edge.
   */
  parametersEdgeIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/edge/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this edge.
   */
  parametersEdgeId(id: number): __Observable<null> {
    return this.parametersEdgeIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersFpResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/fp/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersFp(): __Observable<null> {
    return this.parametersFpResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersFpCreateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/fp/create/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersFpCreate(): __Observable<null> {
    return this.parametersFpCreateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this fp.
   */
  parametersFpIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/fp/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this fp.
   */
  parametersFpId(id: number): __Observable<null> {
    return this.parametersFpIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersGraphResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/graph/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersGraph(): __Observable<null> {
    return this.parametersGraphResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this graph.
   */
  parametersGraphIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/graph/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this graph.
   */
  parametersGraphId(id: number): __Observable<null> {
    return this.parametersGraphIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersGroupResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/group/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersGroup(): __Observable<null> {
    return this.parametersGroupResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this group.
   */
  parametersGroupIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/group/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this group.
   */
  parametersGroupId(id: number): __Observable<null> {
    return this.parametersGroupIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersJobResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/job/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersJob(): __Observable<null> {
    return this.parametersJobResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this job.
   */
  parametersJobIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/job/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this job.
   */
  parametersJobId(id: number): __Observable<null> {
    return this.parametersJobIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersPermissionResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/permission/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersPermission(): __Observable<null> {
    return this.parametersPermissionResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this permission.
   */
  parametersPermissionIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/permission/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this permission.
   */
  parametersPermissionId(id: number): __Observable<null> {
    return this.parametersPermissionIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersTagResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/tag/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersTag(): __Observable<null> {
    return this.parametersTagResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this tag.
   */
  parametersTagIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/tag/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this tag.
   */
  parametersTagId(id: number): __Observable<null> {
    return this.parametersTagIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersTokenAuthResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/token/auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersTokenAuth(): __Observable<null> {
    return this.parametersTokenAuthResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersTokenRefreshResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/token/refresh/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersTokenRefresh(): __Observable<null> {
    return this.parametersTokenRefreshResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  parametersUserResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/user/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  parametersUser(): __Observable<null> {
    return this.parametersUserResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this user.
   */
  parametersUserIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PARAMETERS',
      this.rootUrl + `/user/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this user.
   */
  parametersUserId(id: number): __Observable<null> {
    return this.parametersUserIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ApiService {
}

export { ApiService }
