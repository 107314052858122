import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, finalize } from "rxjs/operators";
import { of } from "rxjs";



/**
 * Data source for the SimpleDropDown view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */

export class SimpleDropDownDataSource extends DataSource<any> {

  // private count: number;
  // private next?: string;
  // private previous?: string;
  // private displayedColumns: string[];

  private subject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  // public countSubject = new BehaviorSubject<string>('');
  // public nextSubject = new BehaviorSubject<string>('');
  // public previousSubject = new BehaviorSubject<string>('');
  // public displayedColumnsSubject = new BehaviorSubject<string[]>([]);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private dataService: any, public paginator: MatPaginator, public sort: MatSort) { 
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.subject.asObservable();
  }

  // getDisplayedColumns(): Observable<any> {
  //   return this.displayedColumnsSubject.asObservable();
  // }

  // getCount(): Observable<any> {
  //   return this.countSubject.asObservable();
  // }

  // getNext(): Observable<any> {
  //   return this.nextSubject.asObservable();
  // }

  // getPrevious(): Observable<any> {
  //   return this.previousSubject.asObservable();
  // }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
    this.loadingSubject.complete();
  }

  loadItems(search?: string, ordering?: string, offset?: number, limit?: number) {

    this.loadingSubject.next(true);
    // var getMethods = (obj) => Object.getOwnPropertyNames(obj).filter(item => typeof obj[item] === 'function')
    // var met = getMethods('datasetList')

     var getMethods = (obj) => {
      let properties = new Set()
      let currentObj = obj
      do {
        Object.getOwnPropertyNames(currentObj).map(item => properties.add(item))
        
      } while ((currentObj = Object.getPrototypeOf(currentObj)))
      return [...properties.keys()].filter(item => typeof obj[item] === 'function' && item.endsWith("List"))
    }

    var methodName = getMethods(this.dataService)[0]

    this.dataService[methodName]({ search, ordering, offset, limit }).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      // .subscribe(contacts => this.contactsSubject.next(contacts));
      .subscribe(response => {
        this.subject.next(response['results']);
        // this.countSubject.next(response['count']);
        // this.nextSubject.next(response['next']);
        // this.previousSubject.next(response['previous']);
        // this.displayedColumnsSubject.next(response['results'] ? Object.keys(response['results'][0]) : []);
      }
      );
  }

  loadPage(search?: string, ordering?: string, offset?: number, limit?: number) {
    this.loadItems(
      search,
      ordering,
      offset,
      limit);
  }

}

