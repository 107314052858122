import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatRow } from '@angular/material';
import { SimpleTableComponent } from '../simple-table/simple-table.component';
import { AlgoService } from 'src/app/core/services';

@Component({
  selector: 'app-algo',
  templateUrl: './algo.component.html',
  styleUrls: ['./algo.component.css'],
  providers: [AlgoService]
})
export class AlgoComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer: MatSidenav;
  @ViewChild('table', { static: false }) table: SimpleTableComponent;
  private clickedRow: MatRow = {};
  private loadAddAlgoComponent = true; //flag to show or hide add api cmponent
  private loadAlgoRunComponent = false; //flag to show or hide dataset details cmponent

  
  private columnList = ['name','description', 'create_date', 'update_date', 'owner', 'tags', 'status', 'action']
  
  headerButtons = [
    {
      title: "ADD GRAPH CONFIG",
      link: "add-algo",
      icon: "add"
    }
  ]

  apiActions = [
    { title: 'View', icon: 'view_column'},
    { title: 'Edit', icon: 'edit'},
    { title: 'Delete', icon: 'delete'},
    { title: 'Schedule', icon: 'schedule'},
    { title: 'Publish', icon: 'publish'}
  ]

  addAPISuccessEvent(): void {
    this.drawer.toggle();
    this.table.refresh();
  };

  algoRunCloseEvent(): void {
    this.drawer.toggle();
    this.loadAddAlgoComponent = true;
    this.loadAlgoRunComponent = false;
  }

  tableRowClickEvent(item): void {
    var row = item.row;
    var source = item.source;

    if (source == null || source.title.toLowerCase() == "view") {
      this.loadAddAlgoComponent = false;
      this.loadAlgoRunComponent = true;
      this.clickedRow = row;
      this.drawer.toggle();
    }
    else {
      alert(source.title + ' clicked!')
    }

  };
  constructor(private dataService: AlgoService) { }

  ngOnInit() {
  }

}
