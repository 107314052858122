import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, finalize } from "rxjs/operators";
import { of } from "rxjs";
import {Pivot} from '../../core/models/pivot'

/**
 * Data source for the SimpleTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */

export class SimpleTableDataSource extends DataSource<any> {

  // private count: number;
  // private next?: string;
  // private previous?: string;
  // private displayedColumns: string[];

  private subject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public countSubject = new BehaviorSubject<string>('');
  public nextSubject = new BehaviorSubject<string>('');
  public previousSubject = new BehaviorSubject<string>('');
  public displayedColumnsSubject = new BehaviorSubject<string[]>([]);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private dataService: any,
    public paginator: MatPaginator,
    public sort: MatSort,
    private columnList,
    private dropColumnList,
    private actionList,
    private dataFilter,
    private serviceUrl,
    private pivot: Pivot) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.subject.asObservable();
  }

  getDisplayedColumns(): Observable<any> {
    return this.displayedColumnsSubject.asObservable();
  }

  getCount(): Observable<any> {
    return this.countSubject.asObservable();
  }

  getNext(): Observable<any> {
    return this.nextSubject.asObservable();
  }

  getPrevious(): Observable<any> {
    return this.previousSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
    this.loadingSubject.complete();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadItems(search?: string, ordering?: string, offset?: number, limit?: number) {

    this.loadingSubject.next(true);
    // var getMethods = (obj) => Object.getOwnPropertyNames(obj).filter(item => typeof obj[item] === 'function')
    // var met = getMethods('datasetList')
    this.delay(10000);

    var getMethods = (obj) => {
      let properties = new Set()
      let currentObj = obj
      do {
        Object.getOwnPropertyNames(currentObj).map(item => properties.add(item))

      } while ((currentObj = Object.getPrototypeOf(currentObj)))
      return [...properties.keys()].filter(item => typeof obj[item] === 'function' && item.endsWith("List"))
    }

    var methodName = getMethods(this.dataService)[0]
    var kwargs = Object.assign({ search, ordering, offset, limit, kwargs}, this.dataFilter);
    if(!this.serviceUrl){
      this.dataService[methodName](kwargs).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(response=> this.onSuccess(response));
    }
    else{
      var url = this.serviceUrl;
      this.dataService[methodName]({ search, ordering, offset, limit, url }).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe(response=> this.onSuccess(response));
    }
    
  }

  onSuccess(response: any) {
    var result = response['results']

    //pivoting
    // if (this.dataFilter) {
    //   result = response['results'].filter(item => {
    //     return item[this.dataFilter['key']] == this.dataFilter['value'];
    //   })
    // }

    this.subject.next(result);
    this.countSubject.next(response['count']);
    this.nextSubject.next(response['next']);
    this.previousSubject.next(response['previous']);
    //remove columns if required
    if (!this.columnList || this.columnList.length == 0) {
      var columnList = response['results'] ? Object.keys(response['results'][0]) : [];
      if (columnList.length > 0 && this.dropColumnList && this.dropColumnList.length > 0) {
        columnList = columnList.filter(f => !this.dropColumnList.includes(f));
      }

      //add action column if required
      if (this.actionList && this.actionList.length > 0) {
        columnList.push('action');
      }
      this.displayedColumnsSubject.next(columnList);
    }
    else {
      this.displayedColumnsSubject.next(this.columnList);
    }

  }

  loadPage(search?: string) {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    var ordering = null;
    if (!this.sort.active || this.sort.direction === '') {
      ordering = null;
    }
    else {
      const direction = this.sort.direction === 'asc' ? '' : '-';
      const orderingField = this.sort.active
      ordering = direction + orderingField
    }

    this.loadItems(
      search,
      ordering,
      offset,
      this.paginator.pageSize);
  }
}

