import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Algo } from 'src/app/core/models';
import { AlgoRun } from 'src/app/core/models/algo-run';
import { GraphService } from 'src/app/core/services/graph.service';
import { EdgeService } from 'src/app/core/services/edge.service';
import { ResourceService } from 'src/app/core/services/resource.service';
import { GraphComponent } from '../graph/graph.component';


@Component({
  selector: 'app-algo-run-details',
  templateUrl: './algo-run-details.component.html',
  styleUrls: ['./algo-run-details.component.css'],
  providers: [GraphService, EdgeService, ResourceService]
})
export class AlgoRunDetailsComponent implements OnInit {
  @Input() algo: Algo;
  @Input() algoRun: AlgoRun;
  @Output() algoRunDetailsCloseEvent = new EventEmitter<any>();
  @ViewChild('graph', { static: false }) graph: GraphComponent;

  subTitle = []
  resourceServiceUrl = this.graphService.rootUrl + '/graph/?group_by=cluster_id&aggregate=id&metric=Count'
  algoRunSummary = {}
  clickedClusterId: number;

  headerButtons = [
    {
      title: 'Close',
      icon: 'cancel'
    }
  ]

  buttonClick(): void {
    if (event.currentTarget['id'] == "Close") {
      this.algoRunDetailsCloseEvent.emit();
    }
  }

  tableRowClickEvent(item): void {
    var row = item.row;
    var source = item.source;

    if (source == null) {
      this.clickedClusterId = row['cluster_id']
      if (this.graph) {
        this.graph.loadGraph(this.clickedClusterId);
      }

    }
    else {
      alert(source.title + ' clicked!')
    }

  };

  constructor(private graphService: GraphService,
    private edgeService: EdgeService,
    private resourceService: ResourceService) { }

  ngOnInit() {
    this.subTitle = [this.algo.name, this.algoRun.name]
    this.algoRunSummary = JSON.parse(this.algoRun['response'])
  }

}
