import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ForceDirectedGraph, Node, Link } from '../../models';
import { D3Service } from '../../services/d3.service'

@Component({
  selector: 'app-graph-visual',
  templateUrl: './graph-visual.component.html',
  styleUrls: ['./graph-visual.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphVisualComponent implements OnInit, AfterViewInit {
  @Input('nodes') nodes: Node[];
  @Input('links') links: Link[];
  graph: ForceDirectedGraph;
  private _options: { width, height } = { width: 600, height: 600 };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.graph.initSimulation(this.options);
  }

  constructor(private d3Service: D3Service, private ref: ChangeDetectorRef) { }

  ngOnInit() {
    debugger;
    if ((this.nodes) && (this.nodes.length > 0)) {
      /** Receiving an initialized simulated graph from our custom d3 service */
      this.graph = this.d3Service.getForceDirectedGraph(this.nodes, this.links, this.options);

      /** Binding change detection check on each tick
       * This along with an onPush change detection strategy should enforce checking only when relevant!
       * This improves scripting computation duration in a couple of tests I've made, consistently.
       * Also, it makes sense to avoid unnecessary checks when we are dealing only with simulations data binding.
       */
      this.graph.ticker.subscribe((d) => {
        this.ref.markForCheck();
      });
    };
  }

  ngAfterViewInit() {
    if (this.graph) {
      this.graph.initSimulation(this.options);
    }
  }

  get options() {
    return this._options;
    // return this._options = {
    //   width: window.innerWidth,
    //   height: window.innerHeight
    // };
  }

}
