import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AlgorunService } from 'src/app/core/services/algorun.service';
import { Algo } from 'src/app/core/models';
import { MatSidenav } from '@angular/material';
import { AlgoRun } from 'src/app/core/models/algo-run';

@Component({
  selector: 'app-algo-run',
  templateUrl: './algo-run.component.html',
  styleUrls: ['./algo-run.component.css'],
  providers: [AlgorunService]
})
export class AlgoRunComponent implements OnInit {
  @Input() clickedRow: Algo;
  @Output() algoRunCloseEvent = new EventEmitter<any>();
  @ViewChild('drawer', { static: false }) drawer: MatSidenav;

  subTitle = []
  algoRun: AlgoRun;
  private loadAlgoRunDetailComponent = false; //flag to show or hide add algo run details component

  private columnList = ['name', 'create_date', 'update_date', 'owner', 'response', 'config', 'status', 'action']
  private dataFilter: AlgorunService.AlgorunListParams;

  headerButtons = [
    {
      title: 'Close',
      icon: 'cancel'
    }
  ]

  private algoRunActions = [
    { title: 'View', icon: 'view_column' },
    { title: 'Logs', icon: 'list' }
  ]

  buttonClick(): void {
    if (event.currentTarget['id'] == "Close") {
      this.algoRunCloseEvent.emit();
    }
  }

  algoRunDetailsCloseEvent(): void {
    this.drawer.toggle();
    this.loadAlgoRunDetailComponent = false;
  };
  
  tableRowClickEvent(item): void {
    var row = item.row;
    var source = item.source;

    if (source == null || source.title.toLowerCase() == "view") {
      this.algoRun = row;
      this.loadAlgoRunDetailComponent = true;
      this.drawer.toggle();
    }
    else {
      alert(source.title + ' clicked!')
    }

  };

  constructor(private dataService: AlgorunService) { }

  ngOnInit() {
    this.dataFilter = { 'algo': this.clickedRow.id.toString() };
    this.subTitle = [this.clickedRow.name];
  }

}
