import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { DatasetService } from 'src/app/core/services';
import { BehaviorSubject } from 'rxjs';
import { Dataset } from 'src/app/core/models';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
declare var Blockly: any;

@Component({
  selector: 'app-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.css'],
  providers: [DatasetService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPolicyComponent implements OnInit, AfterViewInit {

  workspace: any;
  @Output() addAPISuccessEvent = new EventEmitter<any>();
  // @ViewChild('blocklyDiv', { static: false }) blocklyDiv: ElementRef
  blocklyDiv: any;
  // public datasetResult = new BehaviorSubject<any>([]);
  private selectedDataset: any;
  private selectedOverrideDataset: any;

  //provides the reactive form group ability
  addPolicyMetaGroup = this.fb.group({
    name: [null, Validators.required],
    description: [null],
    tags: [null],
    points: [null],
    priority: [null],
    dataset: [null],
    overrideDataset: [null],
  });

  //provides the reactive form group ability
  addPolicyGroup = this.fb.group({

    // node: [null, Validators.required],
    // filters: this.fb.array([this.createFilter()]),
    // edges: this.fb.array([this.createEdge()])
  });

  constructor(private datasetService: DatasetService,
    private fb: FormBuilder) { }

  headerButtons = [
    {
      title: "Save draft",
      icon: 'save_alt'
    },
    {
      title: "Save draft and dry run",
      icon: 'play_circle_outline'
    },
    {
      title: "Save and publish",
      icon: 'publish'
    },
    {
      title: "Cancel",
      icon: 'cancel'
    }
  ]
  buttonClick(): void {
    debugger;
    if (event.currentTarget['id'] == "Cancel") {
      this.addAPISuccessEvent.emit();
    }
    else if (event.currentTarget['id'] == "Save draft") {
      this.addAPISuccessEvent.emit();
    }
  }
  ngOnInit() {

  }

  ngAfterViewInit(): void {
    // this.loadItems();
    // this.blocklyDiv = document.getElementById('blocklyDiv');
    if (!this.workspace) {
      // this.workspace = new Blockly.Workspace();
      // var lookup_block = new Blockly.Block(this.workspace, "lookup_service")
      // lookup_block.appendValueInput('lookup_field').setCheck(null).appendField('lookup: ');
      // lookup_block.appendValueInput('lookup_source').setCheck(null).appendField('in: ');
      // lookup_block.setInputsInline(true);
      // lookup_block.setColour(290);
      // lookup_block.setTooltip('Lookup service');
      // lookup_block.setHelpUrl('');
      // lookup_block.setOutput(true);
      
      this.workspace = Blockly.inject('blocklyDiv', { toolbox: document.getElementById('toolbox'), scrollbars: false });
      this.workspace.addChangeListener((e: Event) => this.myUpdateFunction(e));
      
    }
  }

  private myUpdateFunction(event: Event) {
    var code = Blockly.Python.workspaceToCode(this.workspace);
    document.getElementById('textareajscode').innerHTML = code;
  };

  private exportBlocks() {
    try {
      var xml = Blockly.Xml.workspaceToDom(this.workspace);
      var xml_text = Blockly.Xml.domToText(xml);
      
      var link = document.createElement('a');
      link.download="project.txt";
      link.href="data:application/octet-stream;utf-8," + encodeURIComponent(xml_text);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      window.location.href="data:application/octet-stream;utf-8," + encodeURIComponent(xml_text);
      alert(e);
    }
  }
      
  datasetSelectChanged(event: MatSelectChange): void {
    var datasetId = event.value;
    this.datasetService.datasetRead(datasetId).subscribe(
      result => {
        this.selectedDataset = JSON.parse(result['config']);
        (<any>window).datasetResult = this.selectedDataset;
      },
      err => {
        // this.isError = true;
      },
      () => {
        // this.isError = false;
      }
    )
  };


  lookupDatasetSelectChanged(event: MatSelectChange): void {
    var datasetId = event.value;
    this.datasetService.datasetRead(datasetId).subscribe(
      result => {
        this.selectedOverrideDataset = JSON.parse(result['config']);
        (<any>window).overrideDatasetResult = this.selectedOverrideDataset;
      },
      err => {
        // this.isError = true;
      },
      () => {
        // this.isError = false;
      }
    )
  };
}

