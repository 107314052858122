import { Injectable } from '@angular/core';
import { BaseService as __BaseService } from '../base-service';
import { TokenService } from './token.service';
import { RefreshJSONWebToken } from '../models/refresh-jsonweb-token';
import { JSONWebToken } from '../models/jsonweb-token'
import { Observable as __Observable, Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/shareReplay'
import * as moment from 'moment'
import { UserToken } from '../models/user-token';

@Injectable({
  providedIn: 'root',
})
class AuthService {

  private currentUserSubject: BehaviorSubject<UserToken>;
  public currentUser: Observable<UserToken>;

  // http options used for making API calls
  private httpOptions: any;

  // the actual JWT token
  public token: string;

  // the token expiration date
  public token_expires: Date;

  // the username of the logged in user
  public username: string;

  // error messages received from the login attempt
  public errors: any = [];

  constructor(private tokenService: TokenService) {
    this.currentUserSubject = new BehaviorSubject<UserToken>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserToken {
    return this.currentUserSubject.value;
  };

  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  public login(user: JSONWebToken) {
    return this.tokenService.tokenAuthCreate(user)
      .do(res => this.setSession(res))
      .shareReplay();

    //   var result: boolean = true;
    //   this.tokenService.tokenAuthCreate(user).subscribe(
    //     data => {
    //       this.updateData(data['token']);
    //     },
    //     err => {
    //       this.errors = err['error'];
    //       result = false;
    //     },
    //     () => {
    //       result = true;
    //     }
    //   );
    //   return result;
  }

  private setSession(authResult) {
    this.token = authResult;
    this.errors = [];

    // decode the token to read the username and expiration timestamp
    // const token_parts = this.token.split(/\./);
    // const token_decoded = JSON.parse(window.atob(token_parts[1]));
    // this.token_expires = new Date(token_decoded.exp * 1000);
    // this.username = token_decoded.username;

    // localStorage.setItem('id_token', token_decoded.idToken);
    // localStorage.setItem('token_expires', JSON.stringify(this.token_expires.valueOf()));

    const user: UserToken = {
      token: authResult.token
    };

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);

    // const expiresAt = moment().add(authResult.expiresIn, 'second');

    // localStorage.setItem('id_token', authResult.idToken);
    // localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
  };

  logout() {
    this.token = null;
    this.token_expires = null;
    this.username = null;
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    // localStorage.removeItem("id_token");
    // localStorage.removeItem("expires_at");
  }

  public isLoggedIn() {
    this.token_expires = new Date(localStorage.getItem('token_expires'));
    return moment().isBefore(this.token_expires);
  }

  // isLoggedOut() {
  //   return !this.isLoggedIn();
  // }

  // getExpiration() {
  //   const expiration = localStorage.getItem("expires_at");
  //   const expiresAt = JSON.parse(expiration);
  //   return moment(expiresAt);
  // }

  // Refreshes the JWT token, to extend the time the user is logged in
  public refreshToken() {
    var token: RefreshJSONWebToken;
    var result: boolean = true;

    token.token = this.token;

    return this.tokenService.tokenRefreshCreate(token)
      .do(res => this.setSession)
      .shareReplay();

    // this.tokenService.tokenRefreshCreate(token).subscribe(
    //   data => {
    //     this.updateData(data['token']);
    //   },
    //   err => {
    //     this.errors = err['error'];
    //     result = false;
    //   },
    //   () => {
    //     result = true;
    //   }
    // );
    // return result;
  }

}

module AuthService {
}

export { AuthService }