/* tslint:disable */
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { AggregateService } from './services/aggregate.service';
import { ApiService } from './services/api.service';
import { AlgoService } from './services/algo.service';
import { AlgorunService } from './services/algorun.service';
import { DatasetService } from './services/dataset.service';
import { DatasetschemaService } from './services/datasetschema.service';
import { EdgeService } from './services/edge.service';
import { FpService } from './services/fp.service';
import { GraphService } from './services/graph.service';
import { GroupService } from './services/group.service';
import { JobService } from './services/job.service';
import { PermissionService } from './services/permission.service';
import { TagService } from './services/tag.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AggregateService,
    ApiService,
    AlgoService,
    AlgorunService,
    DatasetService,
    DatasetschemaService,
    EdgeService,
    FpService,
    GraphService,
    GroupService,
    JobService,
    PermissionService,
    TagService,
    TokenService,
    UserService
  ],
})
export class ApiModule { }
