/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FP } from '../models/fp';
@Injectable({
  providedIn: 'root',
})
class FpService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FpService.FpListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  fpListResponse(params: FpService.FpListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<FP>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fp/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<FP>}>;
      })
    );
  }
  /**
   * @param params The `FpService.FpListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  fpList(params: FpService.FpListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<FP>}> {
    return this.fpListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<FP>})
    );
  }

  /**
   * @param data undefined
   */
  fpCreateCreateResponse(data: FP): __Observable<__StrictHttpResponse<FP>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fp/create/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FP>;
      })
    );
  }
  /**
   * @param data undefined
   */
  fpCreateCreate(data: FP): __Observable<FP> {
    return this.fpCreateCreateResponse(data).pipe(
      __map(_r => _r.body as FP)
    );
  }

  /**
   * @param id A unique integer value identifying this fp.
   */
  fpReadResponse(id: number): __Observable<__StrictHttpResponse<FP>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fp/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FP>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this fp.
   */
  fpRead(id: number): __Observable<FP> {
    return this.fpReadResponse(id).pipe(
      __map(_r => _r.body as FP)
    );
  }

  /**
   * @param params The `FpService.FpUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this fp.
   *
   * - `data`:
   */
  fpUpdateResponse(params: FpService.FpUpdateParams): __Observable<__StrictHttpResponse<FP>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fp/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FP>;
      })
    );
  }
  /**
   * @param params The `FpService.FpUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this fp.
   *
   * - `data`:
   */
  fpUpdate(params: FpService.FpUpdateParams): __Observable<FP> {
    return this.fpUpdateResponse(params).pipe(
      __map(_r => _r.body as FP)
    );
  }

  /**
   * @param params The `FpService.FpPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this fp.
   *
   * - `data`:
   */
  fpPartialUpdateResponse(params: FpService.FpPartialUpdateParams): __Observable<__StrictHttpResponse<FP>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/fp/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FP>;
      })
    );
  }
  /**
   * @param params The `FpService.FpPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this fp.
   *
   * - `data`:
   */
  fpPartialUpdate(params: FpService.FpPartialUpdateParams): __Observable<FP> {
    return this.fpPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as FP)
    );
  }

  /**
   * @param id A unique integer value identifying this fp.
   */
  fpDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/fp/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this fp.
   */
  fpDelete(id: number): __Observable<null> {
    return this.fpDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FpService {

  /**
   * Parameters for fpList
   */
  export interface FpListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for fpUpdate
   */
  export interface FpUpdateParams {

    /**
     * A unique integer value identifying this fp.
     */
    id: number;
    data: FP;
  }

  /**
   * Parameters for fpPartialUpdate
   */
  export interface FpPartialUpdateParams {

    /**
     * A unique integer value identifying this fp.
     */
    id: number;
    data: FP;
  }
}

export { FpService }
