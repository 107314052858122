import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css'],
  providers: [JobService]
})
export class JobComponent implements OnInit {
  private columnList = ['name','description','track_id','type', 'create_date', 'update_date', 'owner', 'tags','status', 'action']
  
  private jobsActions = [
    { title: 'View', icon: 'view_column'}
  ]

  constructor(private dataService: JobService) { }

  ngOnInit() {
  }

}
