/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Edge } from '../models/edge';
@Injectable({
  providedIn: 'root',
})
class EdgeService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EdgeService.EdgeListParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `type`:
   *
   * - `target`:
   *
   * - `source`:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   *
   * - `graph`:
   *
   * - `algo_run`:
   */
  edgeListResponse(params: EdgeService.EdgeListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Edge>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.value != null) __params = __params.set('value', params.value.toString());
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.source != null) __params = __params.set('source', params.source.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.graph != null) __params = __params.set('graph', params.graph.toString());
    if (params.algoRun != null) __params = __params.set('algo_run', params.algoRun.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/edge/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Edge>}>;
      })
    );
  }
  /**
   * @param params The `EdgeService.EdgeListParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `type`:
   *
   * - `target`:
   *
   * - `source`:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   *
   * - `graph`:
   *
   * - `algo_run`:
   */
  edgeList(params: EdgeService.EdgeListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Edge>}> {
    return this.edgeListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Edge>})
    );
  }

  /**
   * @param data undefined
   */
  edgeCreateResponse(data: Edge): __Observable<__StrictHttpResponse<Edge>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/edge/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edge>;
      })
    );
  }
  /**
   * @param data undefined
   */
  edgeCreate(data: Edge): __Observable<Edge> {
    return this.edgeCreateResponse(data).pipe(
      __map(_r => _r.body as Edge)
    );
  }

  /**
   * @param id A unique integer value identifying this edge.
   */
  edgeReadResponse(id: number): __Observable<__StrictHttpResponse<Edge>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/edge/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edge>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this edge.
   */
  edgeRead(id: number): __Observable<Edge> {
    return this.edgeReadResponse(id).pipe(
      __map(_r => _r.body as Edge)
    );
  }

  /**
   * @param params The `EdgeService.EdgeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this edge.
   *
   * - `data`:
   */
  edgeUpdateResponse(params: EdgeService.EdgeUpdateParams): __Observable<__StrictHttpResponse<Edge>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/edge/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edge>;
      })
    );
  }
  /**
   * @param params The `EdgeService.EdgeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this edge.
   *
   * - `data`:
   */
  edgeUpdate(params: EdgeService.EdgeUpdateParams): __Observable<Edge> {
    return this.edgeUpdateResponse(params).pipe(
      __map(_r => _r.body as Edge)
    );
  }

  /**
   * @param params The `EdgeService.EdgePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this edge.
   *
   * - `data`:
   */
  edgePartialUpdateResponse(params: EdgeService.EdgePartialUpdateParams): __Observable<__StrictHttpResponse<Edge>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/edge/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edge>;
      })
    );
  }
  /**
   * @param params The `EdgeService.EdgePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this edge.
   *
   * - `data`:
   */
  edgePartialUpdate(params: EdgeService.EdgePartialUpdateParams): __Observable<Edge> {
    return this.edgePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Edge)
    );
  }

  /**
   * @param id A unique integer value identifying this edge.
   */
  edgeDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/edge/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this edge.
   */
  edgeDelete(id: number): __Observable<null> {
    return this.edgeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EdgeService {

  /**
   * Parameters for edgeList
   */
  export interface EdgeListParams {
    value?: string;
    type?: string;
    target?: string;
    source?: string;

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    id?: number;
    graph?: string;
    algoRun?: string;
  }

  /**
   * Parameters for edgeUpdate
   */
  export interface EdgeUpdateParams {

    /**
     * A unique integer value identifying this edge.
     */
    id: number;
    data: Edge;
  }

  /**
   * Parameters for edgePartialUpdate
   */
  export interface EdgePartialUpdateParams {

    /**
     * A unique integer value identifying this edge.
     */
    id: number;
    data: Edge;
  }
}

export { EdgeService }
