import { Component, OnInit } from '@angular/core';
import { DatasetService } from 'src/app/core/services';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css'],
  providers: [DatasetService]
})
export class ActivityComponent implements OnInit {

  constructor(private dataService: DatasetService) {
  }

  ngOnInit() {
  }
}
