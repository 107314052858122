import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: AuthService
    ) {}

    // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //     return this._amplifyService.auth().currentAuthenticatedUser()
    //       .then(() => {
    //         return true;
    //       })
    //       .catch(() => {
    //         this._router.navigate(['/login']);
    //         return false;
    //       });
    //   }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const currentUser = this.userService.currentUserValue;

        if (currentUser) {
            // authorised so return true
            return true;
        }
        

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}