import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer: MatSidenav;
  
  headerButtons = [
    {
      title: "ADD NEW POLICY",
      link: "add-policy",
      icon: "add"
    }
  ]

  addAPISuccessEvent(): void {
    this.drawer.toggle();
  };

  constructor() { }

  ngOnInit() {
  }

}
