/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tag } from '../models/tag';
@Injectable({
  providedIn: 'root',
})
class TagService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TagService.TagListParams` containing the following parameters:
   *
   * - `updated_by`:
   *
   * - `search`: A search term.
   *
   * - `owner`:
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   */
  tagListResponse(params: TagService.TagListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Tag>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.updatedBy != null) __params = __params.set('updated_by', params.updatedBy.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.owner != null) __params = __params.set('owner', params.owner.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tag/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Tag>}>;
      })
    );
  }
  /**
   * @param params The `TagService.TagListParams` containing the following parameters:
   *
   * - `updated_by`:
   *
   * - `search`: A search term.
   *
   * - `owner`:
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `id`:
   */
  tagList(params: TagService.TagListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Tag>}> {
    return this.tagListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Tag>})
    );
  }

  /**
   * @param data undefined
   */
  tagCreateResponse(data: Tag): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tag/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * @param data undefined
   */
  tagCreate(data: Tag): __Observable<Tag> {
    return this.tagCreateResponse(data).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * @param id A unique integer value identifying this tag.
   */
  tagReadResponse(id: number): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tag/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this tag.
   */
  tagRead(id: number): __Observable<Tag> {
    return this.tagReadResponse(id).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * @param params The `TagService.TagUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  tagUpdateResponse(params: TagService.TagUpdateParams): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tag/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * @param params The `TagService.TagUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  tagUpdate(params: TagService.TagUpdateParams): __Observable<Tag> {
    return this.tagUpdateResponse(params).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * @param params The `TagService.TagPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  tagPartialUpdateResponse(params: TagService.TagPartialUpdateParams): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/tag/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * @param params The `TagService.TagPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this tag.
   *
   * - `data`:
   */
  tagPartialUpdate(params: TagService.TagPartialUpdateParams): __Observable<Tag> {
    return this.tagPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * @param id A unique integer value identifying this tag.
   */
  tagDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/tag/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this tag.
   */
  tagDelete(id: number): __Observable<null> {
    return this.tagDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TagService {

  /**
   * Parameters for tagList
   */
  export interface TagListParams {
    updatedBy?: string;

    /**
     * A search term.
     */
    search?: string;
    owner?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    name?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    id?: number;
  }

  /**
   * Parameters for tagUpdate
   */
  export interface TagUpdateParams {

    /**
     * A unique integer value identifying this tag.
     */
    id: number;
    data: Tag;
  }

  /**
   * Parameters for tagPartialUpdate
   */
  export interface TagPartialUpdateParams {

    /**
     * A unique integer value identifying this tag.
     */
    id: number;
    data: Tag;
  }
}

export { TagService }
