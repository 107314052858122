/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AlgoRun } from '../models/algo-run';
@Injectable({
  providedIn: 'root',
})
class AlgorunService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AlgorunService.AlgorunListParams` containing the following parameters:
   *
   * - `updated_by`:
   *
   * - `status`:
   *
   * - `search`: A search term.
   *
   * - `owner`:
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `job`:
   *
   * - `id`:
   *
   * - `algo_run_graph`:
   *
   * - `algo_run_edges`:
   *
   * - `algo`:
   */
  algorunListResponse(params: AlgorunService.AlgorunListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<AlgoRun>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.updatedBy != null) __params = __params.set('updated_by', params.updatedBy.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.owner != null) __params = __params.set('owner', params.owner.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.job != null) __params = __params.set('job', params.job.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.algoRunGraph != null) __params = __params.set('algo_run_graph', params.algoRunGraph.toString());
    if (params.algoRunEdges != null) __params = __params.set('algo_run_edges', params.algoRunEdges.toString());
    if (params.algo != null) __params = __params.set('algo', params.algo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/algorun/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<AlgoRun>}>;
      })
    );
  }
  /**
   * @param params The `AlgorunService.AlgorunListParams` containing the following parameters:
   *
   * - `updated_by`:
   *
   * - `status`:
   *
   * - `search`: A search term.
   *
   * - `owner`:
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `name`:
   *
   * - `limit`: Number of results to return per page.
   *
   * - `job`:
   *
   * - `id`:
   *
   * - `algo_run_graph`:
   *
   * - `algo_run_edges`:
   *
   * - `algo`:
   */
  algorunList(params: AlgorunService.AlgorunListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<AlgoRun>}> {
    return this.algorunListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<AlgoRun>})
    );
  }

  /**
   * @param data undefined
   */
  algorunCreateResponse(data: AlgoRun): __Observable<__StrictHttpResponse<AlgoRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/algorun/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlgoRun>;
      })
    );
  }
  /**
   * @param data undefined
   */
  algorunCreate(data: AlgoRun): __Observable<AlgoRun> {
    return this.algorunCreateResponse(data).pipe(
      __map(_r => _r.body as AlgoRun)
    );
  }

  /**
   * @param id A unique integer value identifying this algo run.
   */
  algorunReadResponse(id: number): __Observable<__StrictHttpResponse<AlgoRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/algorun/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlgoRun>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this algo run.
   */
  algorunRead(id: number): __Observable<AlgoRun> {
    return this.algorunReadResponse(id).pipe(
      __map(_r => _r.body as AlgoRun)
    );
  }

  /**
   * @param params The `AlgorunService.AlgorunUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this algo run.
   *
   * - `data`:
   */
  algorunUpdateResponse(params: AlgorunService.AlgorunUpdateParams): __Observable<__StrictHttpResponse<AlgoRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/algorun/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlgoRun>;
      })
    );
  }
  /**
   * @param params The `AlgorunService.AlgorunUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this algo run.
   *
   * - `data`:
   */
  algorunUpdate(params: AlgorunService.AlgorunUpdateParams): __Observable<AlgoRun> {
    return this.algorunUpdateResponse(params).pipe(
      __map(_r => _r.body as AlgoRun)
    );
  }

  /**
   * @param params The `AlgorunService.AlgorunPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this algo run.
   *
   * - `data`:
   */
  algorunPartialUpdateResponse(params: AlgorunService.AlgorunPartialUpdateParams): __Observable<__StrictHttpResponse<AlgoRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/algorun/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlgoRun>;
      })
    );
  }
  /**
   * @param params The `AlgorunService.AlgorunPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this algo run.
   *
   * - `data`:
   */
  algorunPartialUpdate(params: AlgorunService.AlgorunPartialUpdateParams): __Observable<AlgoRun> {
    return this.algorunPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as AlgoRun)
    );
  }

  /**
   * @param id A unique integer value identifying this algo run.
   */
  algorunDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/algorun/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this algo run.
   */
  algorunDelete(id: number): __Observable<null> {
    return this.algorunDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AlgorunService {

  /**
   * Parameters for algorunList
   */
  export interface AlgorunListParams {
    updatedBy?: string;
    status?: string;

    /**
     * A search term.
     */
    search?: string;
    owner?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    name?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    job?: string;
    id?: number;
    algoRunGraph?: string;
    algoRunEdges?: string;
    algo?: string;
  }

  /**
   * Parameters for algorunUpdate
   */
  export interface AlgorunUpdateParams {

    /**
     * A unique integer value identifying this algo run.
     */
    id: number;
    data: AlgoRun;
  }

  /**
   * Parameters for algorunPartialUpdate
   */
  export interface AlgorunPartialUpdateParams {

    /**
     * A unique integer value identifying this algo run.
     */
    id: number;
    data: AlgoRun;
  }
}

export { AlgorunService }
