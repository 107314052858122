import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from './shared/modules/material/material.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleTabComponent } from './components/simple-tab/simple-tab.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { SimpleFormComponent } from './components/simple-form/simple-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiModule } from './core/api.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivityComponent } from './components/activity/activity.component';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AddPolicyComponent } from './components/add-policy/add-policy.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { PolicyComponent } from './components/policy/policy.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { DatasetComponent } from './components/dataset/dataset.component';
import { IamComponent } from './components/iam/iam.component';
import { BillingComponent } from './components/billing/billing.component';
import { AlgoComponent } from './components/algo/algo.component';
import { ReportComponent } from './components/report/report.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AddAlgoComponent } from './components/add-algo/add-algo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SimpleDropdownComponent } from './components/simple-dropdown/simple-dropdown.component';
import { DerpPipe } from './util/pipes/derp.pipe';
import { LoginComponent } from './components/login/login.component';
import { BaseComponent } from './components/base/base.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { AddApiComponent } from './components/add-api/add-api.component'
import { ReplacePipeModule } from 'src/app/util/pipes/replace.pipe';
import { DatasetDetailsComponent } from './components/dataset-details/dataset-details.component';
import { JobComponent } from './components/job/job.component';
import { SwaggerComponent } from './components/swagger/swagger.component'
import { SafePipe } from './util/pipes/safe.pipe';
import { CronEditorComponent } from './components/cron-editor/cron-editor.component'
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { CommonModule } from '@angular/common';
import { AlgoRunComponent } from './components/algo-run/algo-run.component';
import { AlgoRunDetailsComponent } from './components/algo-run-details/algo-run-details.component';
import { UserComponent } from './components/user/user.component';
import { GroupComponent } from './components/group/group.component';
import { GraphComponent } from './components/graph/graph.component';
import { SHARED_COMPONENTS } from './shared/components'
import { D3_DIRECTIVES } from './shared/directives'
import { D3Service } from './shared/services/d3.service'

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    SimpleTabComponent,
    SimpleTableComponent,
    SimpleFormComponent,
    ActivityComponent,
    HomeComponent,
    DashboardComponent,
    AddPolicyComponent,
    BreadcrumbComponent,
    PolicyComponent,
    DatasetComponent,
    IamComponent,
    BillingComponent,
    AlgoComponent,
    ReportComponent,
    PageHeaderComponent,
    AddAlgoComponent,
    SimpleDropdownComponent,
    DerpPipe,
    LoginComponent,
    BaseComponent,
    AddApiComponent,
    DatasetDetailsComponent,
    JobComponent,
    SwaggerComponent,
    CronEditorComponent,
    TimePickerComponent,
    SafePipe,
    AlgoRunComponent,
    AlgoRunDetailsComponent,
    UserComponent,
    GroupComponent,
    GraphComponent,
    ...SHARED_COMPONENTS,
    ...D3_DIRECTIVES
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ApiModule,
    HttpClientModule,
    NgDynamicBreadcrumbModule,
    MatTooltipModule,
    ReplacePipeModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    GoogleChartsModule.forRoot('AIzaSyCFYoKsnwdoIJQTpjvOMrttaJjgXZk491o')
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [D3Service,
    JwtInterceptor,
    { provide: HTTP_INTERCEPTORS, useExisting: JwtInterceptor, multi: true },
  ]
})
export class AppModule { }