import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [UserService]
})
export class UserComponent implements OnInit {
  private columnList = ['username', 'first_name', 'last_name', 'email', 'last_login', 'is_active', 'date_joined', 'user_permissions', 'action']

  constructor(private dataService: UserService) { }

  ngOnInit() {
  }

}
