/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Group } from '../models/group';
@Injectable({
  providedIn: 'root',
})
class GroupService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GroupService.GroupListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  groupListResponse(params: GroupService.GroupListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Group>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<Group>}>;
      })
    );
  }
  /**
   * @param params The `GroupService.GroupListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  groupList(params: GroupService.GroupListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<Group>}> {
    return this.groupListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<Group>})
    );
  }

  /**
   * @param data undefined
   */
  groupCreateResponse(data: Group): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * @param data undefined
   */
  groupCreate(data: Group): __Observable<Group> {
    return this.groupCreateResponse(data).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * @param id A unique integer value identifying this group.
   */
  groupReadResponse(id: number): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this group.
   */
  groupRead(id: number): __Observable<Group> {
    return this.groupReadResponse(id).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * @param params The `GroupService.GroupUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this group.
   *
   * - `data`:
   */
  groupUpdateResponse(params: GroupService.GroupUpdateParams): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/group/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * @param params The `GroupService.GroupUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this group.
   *
   * - `data`:
   */
  groupUpdate(params: GroupService.GroupUpdateParams): __Observable<Group> {
    return this.groupUpdateResponse(params).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * @param params The `GroupService.GroupPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this group.
   *
   * - `data`:
   */
  groupPartialUpdateResponse(params: GroupService.GroupPartialUpdateParams): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/group/${params.id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * @param params The `GroupService.GroupPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this group.
   *
   * - `data`:
   */
  groupPartialUpdate(params: GroupService.GroupPartialUpdateParams): __Observable<Group> {
    return this.groupPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * @param id A unique integer value identifying this group.
   */
  groupDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/group/${id}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this group.
   */
  groupDelete(id: number): __Observable<null> {
    return this.groupDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GroupService {

  /**
   * Parameters for groupList
   */
  export interface GroupListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for groupUpdate
   */
  export interface GroupUpdateParams {

    /**
     * A unique integer value identifying this group.
     */
    id: number;
    data: Group;
  }

  /**
   * Parameters for groupPartialUpdate
   */
  export interface GroupPartialUpdateParams {

    /**
     * A unique integer value identifying this group.
     */
    id: number;
    data: Group;
  }
}

export { GroupService }
