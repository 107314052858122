import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.sass']
})
export class PageHeaderComponent implements OnInit {
  @Input() icon;
  @Input() title;
  @Input() subTitle;
  @Input() headerButtons;

  @Output() buttonEvent = new EventEmitter<any>();

  buttonClickEvent() {
    this.buttonEvent.emit();
  }

  constructor() {
  }

  ngOnInit() {
  }

}
