import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ResourceService } from 'src/app/core/services/resource.service';

@Component({
  selector: 'app-dataset-details',
  templateUrl: './dataset-details.component.html',
  styleUrls: ['./dataset-details.component.css'],
  providers: [ResourceService]
})
export class DatasetDetailsComponent implements OnInit {

  @Output() detailCloseEvent = new EventEmitter<any>();
  @Input() clickedRow;
  private isTableVisible: boolean = false; //flag to lazy load the table

  headerButtons = [
    {
      title: 'Close',
      icon: 'cancel'
    }
  ]

  buttonClick(): void {
    if (event.currentTarget['id'] == "Close") {
      this.detailCloseEvent.emit();
    }
  }

  constructor(private dataService: ResourceService) { }

  ngOnInit() {
  }

}
